import { useState } from 'react';
import { kebabCase } from 'lodash';

import Layout from 'layouts/default';

import IndustryCard from 'components/content-blocks/customer-feature-benefit';
import { FeaturesList } from 'components/content-blocks/features-list';
import HighlightCards from 'components/content-blocks/highlight';
import MediaObject from 'components/content-blocks/media-object';
import MinimalBlogArticles from 'components/content-blocks/minimal-blog-articles';
import { SignUpTrialLength } from 'components/forms/constants';
import Awards from 'components/globals/awards';
import MoreIndustries from 'components/globals/more-industries';
import PayrollIntegrations from 'components/globals/payroll-integrations';
import Yoast from 'components/globals/yoast';
import { HeroIndustry } from 'components/hero/industry';
import Slider from 'components/sliders/industry-reviews';

import './styles/industries.scss';

export default function IndustriesTemplate({ pageContext }) {
  const globalParentComponents = pageContext.globalIndustryParent.edges[0].node.acf;
  const yoast = pageContext.data.yoast_head_json;
  const hero = pageContext.data.acf.hero;
  const customerCard = pageContext.data.acf.customer_feature_benefit;
  const mediaGroup1 = pageContext.data.acf.media_object.slice(0, 4);
  const mediaGroup2 = pageContext.data.acf.media_object.length > 4 ? pageContext.data.acf.media_object.slice(4) : [globalParentComponents.media_object[0]];
  const industryReviews = pageContext.data.acf.industry_reviews;
  const integrations = pageContext.integrations;
  const articles = pageContext.data.acf.minimal_blog_articles;
  const moreIndustries = pageContext.globalIndustryMenu;
  const awards = pageContext.globalAwards;
  const awardsCategory = pageContext.data.acf.awards_category;
  const cards1 = pageContext.data.acf.cards_1;
  const cards2 = pageContext.data.acf.cards_2;
  const featuresList = globalParentComponents.features_list;
  const faq = pageContext.data.acf.faq;

  const [activeIndustryIndex, setActiveIndustryIndex] = useState(0);

  const trialLength = pageContext.data.slug === 'franchise' ? SignUpTrialLength.REFER_TRIAL_LENGTH : SignUpTrialLength.DEFAULT_TRIAL_LENGTH;

  return (
    <Layout 
      faq={ faq }
      signupModalSubmit={ hero.signup.modal_submit_button_text
      }>
      <Yoast { ...yoast } />
      <div className={ `industry-template-container ${kebabCase(pageContext.slug)}-page-container` } data-signal={ pageContext.data.wordpress_id }>
        <HeroIndustry
          className="hero-section"
          fieldLayout="stack"
          trialLength={ trialLength }
          { ...hero }
        />
        <HighlightCards
          className="industry-highlights"
          { ...cards1 }
          hasImage
          hasTitle
        />

        {mediaGroup1.map((mediaObject, index) => (
          <MediaObject { ...mediaObject } key={ `firstMediaGroup-${index}` } className={ index % 2 == 0 ? 'reverse' : '' } />
        ))}

        <IndustryCard { ...customerCard[0] } />

        {industryReviews &&  (
          <section className="section industry-reviews-section">
            <div className="container-large">
              <div className="review-header">
                <h4 className="subtitle is-spaced has-text-centered">
                  {industryReviews.sub_title}
                </h4>
                <h2 className="title has-text-centered">
                  {industryReviews.title}
                </h2>
              </div>
              <div className="wiw-tabs">
                <div className="industry-buttons is-hidden-mobile is-flex is-justified-center">
                  {industryReviews.reviews.length > 1 && industryReviews.reviews.map((category, i) => {
                    return (
                      <button
                        key={ `cta-button-${i}` }
                        className={ `cta-button ${
                          i === activeIndustryIndex ? 'is-active' : ''
                        }` }
                        onClick={ () => {
                          setActiveIndustryIndex(i);
                        } }
                      >
                        {category.type}
                        <div
                          className={ `triangle ${
                            i === activeIndustryIndex ? 'is-active' : ''
                          }` }
                        />
                      </button>
                    );
                  })}
                </div>
                <Slider
                  card={ industryReviews.reviews }
                  hasOneSlideVisible
                  startingIndex={ activeIndustryIndex }
                  activeIndex={ activeIndustryIndex }
                />
              </div>
            </div>
          </section>
        )}

        {cards2 && <section className="section industry-types-section">
          <div className="container">
            <HighlightCards
              { ...cards2 }
              className="industry-types has-text-centered"
              hasTitle
            />
          </div>
        </section>}

        {mediaGroup2.map((mediaObject, index) => (
          <MediaObject { ...mediaObject } key={ `secondMediaGroup-${index}` } className={ index % 2 == 0 ? 'reverse' : '' } />
        ))}

        <PayrollIntegrations { ...integrations } />
        <Awards { ...awards } category={ awardsCategory } />
        <FeaturesList { ...featuresList } />
        <MinimalBlogArticles { ...articles } />
        <MoreIndustries { ...moreIndustries } />
      </div>
    </Layout>
  );
}
